import React from 'react';

const Gallery = ({ images }) => {
  return (
    <div className="flex flex-col justify-center items-center ">
      {images.map((image, index) => (
        <div key={index} className="m-2">
          <img src={image} alt={`gallery-${index}`} className="object-contain" />
        </div>
      ))}
    </div>
  );
};

export default Gallery;
