import React, { useState, useEffect } from "react";
import Gallery from "./Gallery";
import smirk1 from "./assets/smirk1.png";
import smirk2 from "./assets/smirk2.png";
import smirk3 from "./assets/smirk3.png";
import smirk4 from "./assets/smirk4.png";
import smirk5 from "./assets/smirk5.png";
import smirk6 from "./assets/smirk6.png";
import smirk7 from "./assets/smirk7.png";
import smirk8 from "./assets/smirk8.png";
import smirk9 from "./assets/smirk9.png";
import smirk10 from "./assets/smirk10.png";
import smirk11 from "./assets/smirk11.png";
import smirk12 from "./assets/smirk12.png";
import smirk13 from "./assets/smirk13.png";
import smirk from "./assets/smirk.jpeg";
import suburbs from "./assets/suburbs.webp";
import smirkMeme1 from "./assets/smirkMeme1.jpeg";
import smirkMeme2 from "./assets/smirkMeme2.jpeg";
import smirkMeme3 from "./assets/photo_2023-10-22 02.42.55.jpeg"
import smirkMeme4 from "./assets/photo_2023-10-22 02.43.11.jpeg"
import smirkMeme5 from "./assets/photo_2023-10-22 02.43.24.jpeg"
import smirkMeme6 from "./assets/photo_2023-10-22 02.43.29.jpeg"
import smirkMeme7 from "./assets/photo_2023-10-22 02.43.32.jpeg"
import smirkMeme8 from "./assets/photo_2023-10-22 02.43.35.jpeg"
import smirkMeme9 from "./assets/photo_2023-10-22 02.43.37.jpeg"
import smirkMeme10 from "./assets/photo_2023-10-22 02.43.40.jpeg"
import smirkMeme11 from "./assets/photo_2023-10-22 02.43.46.jpeg"

import Info from "./Info";
import rock from "./assets/rock.gif";

import { SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";

const smirks = [
  smirk1,
  smirk2,
  smirk3,
  smirk4,
  smirk5,
  smirk6,
  smirk7,
  smirk8,
  smirk9,
  smirk10,
  smirk11,
  smirk12,
  smirk13,
];

const animations = [
  "animate-spin",
  "animate-ping",
  "animate-pulse",
  "animate-bounce",
];

const getRandomSize = () => {
  // You can adjust these values based on how large you want the images to be on different screen sizes
  const maxSize = window.innerWidth >= 640 ? 50 : 20; // if the screen width is 640px (md) or larger, the max size is 50, else it's 20
  const minSize = 10;

  const size = Math.random() * (maxSize - minSize) + minSize;
  return `${size}rem`;
};

const getRandomPosition = () => {
  const yPercent = window.innerWidth >= 640 ? 3 : 2;
  const randomPercentageX = Math.floor(Math.random() * 100);
  const randomPercentageY = Math.floor((Math.random() * 100) / yPercent);

  return [randomPercentageX, randomPercentageY];
};

const backgroundStyle = {
  backgroundImage: `url(${suburbs})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
};

const galleryImages = [smirkMeme1, smirkMeme2, smirkMeme3, smirkMeme4, smirkMeme5, smirkMeme6, smirkMeme7, smirkMeme8, smirkMeme9, smirkMeme10, smirkMeme11];

const Smirk = () => {
  const [randomStyles, setRandomStyles] = useState({});

  // Create a function that generates random styles
  const generateRandomStyles = () => {
    const randomDegree = Math.random() * 360;
    const randomDepth = Math.random() * 1000; // Random depth for 3D effect
    const randomPerspective = `${Math.random() * 500}px`;
    const fastRandomDuration = Math.random() * 0.4 + 0.1;

    // Create extreme rotation settings, spinning multiple times
    const extremeRotation = `rotate(${Math.random() * 360 * 5}deg)`; // Random rotations between 0 and 1800 degrees

    // Generate a random scale factor to combine with rotation
    // Values between 1 and 1.5 to avoid too much distortion but add a bit of variability
    const randomScale = Math.random() * 0.5 + 1;

    // Create random 3D transformation settings for added chaos
    const randomTranslate = `translate3d(${Math.random() * 50 - 25}px, ${
      Math.random() * 50 - 25
    }px, ${Math.random() * 50 - 25}px)`;

    // Generate random keyframes for chaotic animation
    const randomKeyframe = {
      transform: `
      rotate(${randomDegree}deg) 
      scale(${randomScale}) 
      translate3d(${Math.random() * 100 - 50}px, ${
        Math.random() * 100 - 50
      }px, ${randomDepth}px)
    `,
      perspective: randomPerspective,
    };

    const animationName = `spin-animation-${Math.random()}`;

    // Create a new style element to hold our keyframes
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
    @keyframes ${animationName} {
      0% { transform: rotate(0deg); }
      100% { ${randomKeyframe.transform} }
    }
  `;
    document.getElementsByTagName("head")[0].appendChild(style);

    // Apply the random animation and other styles
    const newStyle = {
      animationName: animationName,
      animationDuration: `${fastRandomDuration}s`, // very fast animation
      animationIterationCount: "infinite", // continuous spinning
      animationTimingFunction: "linear", // consistent pacing
      ...randomKeyframe, // Apply our random transformations
    };

    setRandomStyles(newStyle);
  };

  // This effect will change the styles at a random interval
  useEffect(() => {
    const interval = setInterval(() => {
      generateRandomStyles();
    }, Math.random() * 2000 + 1000); // change styles at a random interval (between 1-3 seconds here)

    return () => clearInterval(interval); // cleanup the interval on component unmount
  }, []);

  return (
    <div>
      <div className="relative  overflow-x-hidden" style={{ height: "100vh" }}>
        <div className="w-full bg-black py-4 fixed top-0 left-0 z-50">
          <div className="container mx-auto px-4 text-center sm:flex sm:justify-between sm:items-center">
            <span className="text-white md:text-lg text-sm text-2xl mb-2 sm:mb-0">
              0xc84cbd0F8215393c20ab12A83a00E8F344356068
            </span>

            <div className="space-x-4">
              <a
                href="https://uniswap.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-xl hover:text-gray-300 underline"
              >
                Uniswap
              </a>
              <a
                href="https://t.me/smirkcoin"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-xl hover:text-gray-300 underline"
              >
                Telegram
              </a>
              <a
                href="https://twitter.com/smirk_coin"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-xl hover:text-gray-300 underline"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
        <div
          style={backgroundStyle}
          className="inset-0  bg-lime-500 overflow-y-hidden"
        >
          <div className="text-center absolute  rounded-xl inset-x-0 top-1/2 transform -translate-y-1/2 z-10">
            <p className="text-5xl font-comic tracking-tighter text-white">
              $SMIRK
            </p>
          </div>

          <div className="absolute inset-0 flex items-center justify-center z-[5]">
            <img
              src={smirk}
              alt="center-smirk"
              className="object-contain h-1/2 sm:h-1/2 md:h-1/2"
            />
          </div>

          {smirks.map((smirk, index) => {
            const [x, y] = getRandomPosition();
            const size = getRandomSize();
            const animation =
              animations[Math.floor(Math.random() * animations.length)];

            return (
              <img
                key={index}
                src={smirk}
                alt={`smirk-${index}`}
                className={`${animation} absolute`}
                style={{
                  ...randomStyles, // apply the random styles here
                  width: size,
                  height: size,
                  left: `${x}%`,
                  top: `${y}%`,
                  transform: `translate(-50%, -50%)`,
                  zIndex: 0,
                }}
                onMouseOver={generateRandomStyles}
              />
            );
          })}
        </div>

        <div className="pt-10 pb-10 z-[1000]">
          <Info />
        </div>
        <div id="gallery" className="pt-10 pb-10">
          <div className="flex md:flex-row flex-col justify-between items-center px-10">
            <img src={rock} className="py-20" />
            <div className="Uniswap">
              <SwapWidget />
            </div>
          </div>{" "}
          <h2 className="text-3xl text-center mb-5">Gallery</h2>{" "}
          <Gallery images={galleryImages} />
        </div>
      </div>
    </div>
  );
};

export default Smirk;
