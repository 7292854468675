import './App.css';
import Smirk from './Smirk';

function App() {
  return (
    <div className="App">
      <Smirk />
    </div>
  );
}

export default App;
