import React from "react";
import smirkBen from "./assets/smirkBen.webp";
import smirkBeetle from "./assets/smirkBeetle.webp";

const Info = () => {
  return (
    <div className="flex flex-col justify-center items-center z-[10]">
      <div className="flex flex-col md:flex-row items-center justify-between w-full">
        <img src={smirkBen} className="w-[30%]" />
        <p className="text-center md:ml-10">
          <spa className="font-extrabold">About SMIRK</spa>
          <br />
          $SMIRK embody the expression we all know and love.
          <br /> It excites, is dominant, and emphasizes wit and power. The
          Kevin James smirking getty image has taken the world by storm with
          over 1 billion impressions worldwide. Now Smirk has come for the
          blockchain
        </p>
        <img src={smirkBeetle} />
      </div>
    </div>
  );
};

export default Info;
